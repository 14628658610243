













































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheSecondFactorLoginStep',
  components: {
    BaseInput: () =>
      import(
        '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'
      ),
  },
})
export default class TheSecondFactorLoginStep extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  @Prop({ type: Boolean, default: false }) readonly canRecover!: boolean
  @Prop({ type: String, default: '' }) readonly error!: string
  @Prop({ type: String, default: '' }) readonly hint!: string
  @Prop({ type: String, default: '' }) readonly phone!: string
  @Prop({ type: String, required: true }) readonly value!: string

  onForgotPasswordClick () {
    this.$emit('forgot')
    window.goal('loginAction', { login: '2fa: кнопка "Забыли пароль"' })
  }
}
